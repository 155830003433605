import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ImpressumPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <h1>
      Impressum <small>gemäß § 5 Telemediengesetz</small>
    </h1>
    <address>
      Frank Kürzel
      <br />
      Eichestrasse 26
      <br />
      64372 Ober-Ramstadt
      <br />
      <br />
      Mobil: +49 177 2571000
      <br />
      Privat: +49 6154 576558
      <br />
      E-Mail: frank (at) kuerzel.net
    </address>
    <p>Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG: 111 538 172</p>
  </Layout>
)

export default ImpressumPage
